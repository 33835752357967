<template>
  <div>

    <collection-add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :brand-options="brandOptions"
      :user-role="userRole"
      @refetch-data="fetchCollections"
    />

    <collection-edit
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :brand-options="brandOptions"
      :user-role="userRole"
      :edit-collection-id="editCollectionId"
      :edit-collection-name="editCollectionName"
      :edit-collection-brand="editCollectionBrand"
      @refetch-data="fetchCollections"
    />

    <!-- Filters -->
    <collection-filters
      v-if="userRole != 'brand-manager'"
      :brand-filter.sync="brandFilter"
      :brand-options="brandOptions"
      :user-role="userRole"
    />

    <b-button
      class="mb-2"
      variant="primary"
      @click="showAddForm"
    >
      <span class="text-nowrap">Add New Collection</span>
    </b-button>

    <b-list-group>
      <b-list-group-item
        v-for="collection in collectionsData"
        :key="collection.id"
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          {{ collection.name }}<br>
          <b-badge v-if="userRole != 'brand-manager'">
            {{ collection.brand_name }}
          </b-badge>
        </div>
        <div>
          <b-button
            variant="primary"
            :to="{ name: 'apps-styles-list', query: { collectionFilter: collection.id } }"
          >View Styles</b-button>
          <b-dropdown
            variant="link"
            no-caret
            dropleft
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="editCollection(collection)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'apps-styles-list', query: { collectionFilter: collection.id } }"
            >
              <feather-icon icon="PackageIcon" />
              <span class="align-middle ml-50">View Styles</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BBadge, BButton, BDropdown, BDropdownItem, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import {
  onMounted, onUnmounted, ref, watch,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import CollectionAddNew from '@/views/pages/collections/CollectionAddNew.vue'
import CollectionEdit from '@/views/pages/collections/CollectionEdit.vue'
import CollectionFilters from '@/views/pages/collections/CollectionFilters.vue'

/* eslint-disable global-require */
export default {
  components: {
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,

    CollectionAddNew,
    CollectionEdit,
    CollectionFilters,
  },
  setup() {
    const isAddNewSidebarActive = ref(false)
    const isEditSidebarActive = ref(false)
    const collectionsData = ref([])
    const brandOptions = ref([])
    const name = ''
    const brandFilter = ref(+router.currentRoute.query.brandFilter || null)
    const brand = null
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const editCollectionId = ref(0)
    const editCollectionName = ref('')
    const editCollectionBrand = ref(0)
    const STORE_MODULE_NAME = 'collections'

    const collectionStore = {
      namespaced: true,
      state: {},
      getters: {},
      mutations: {},
      actions: {
        fetchCollections(ctx, queryParams) {
          return new Promise((resolve, reject) => {
            axios
              .get('/api/v1/collections/', { params: queryParams })
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        fetchBrands() {
          return new Promise((resolve, reject) => {
            axios
              .get('/api/v1/collections/brand_options/')
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
      },
    }

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, collectionStore)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
      }
    })

    const fetchCollections = () => {
      store.dispatch('collections/fetchCollections',
        { brand: brandFilter.value })
        .then(response => { collectionsData.value = response.data })
    }

    const fetchBrands = () => {
      store.dispatch('collections/fetchBrands')
        .then(response => { brandOptions.value = response.data.brands.options })
    }

    const showAddForm = () => {
      isAddNewSidebarActive.value = true
    }

    const editCollection = collection => {
      isEditSidebarActive.value = true
      editCollectionId.value = collection.id
      editCollectionName.value = collection.name
      editCollectionBrand.value = collection.brand
    }

    watch([brandFilter], () => {
      fetchCollections()
    })

    onMounted(fetchCollections)
    onMounted(fetchBrands)

    return {
      isAddNewSidebarActive,
      isEditSidebarActive,

      collectionsData,
      brandOptions,
      name,
      brandFilter,
      brand,
      userRole,
      editCollectionId,
      editCollectionName,
      editCollectionBrand,

      fetchCollections,
      showAddForm,
      editCollection,
    }
  },
  data() {
    return {
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
